import { useCallback, useEffect, useRef } from 'react';

function OutsideClickHandler({
  children,
  onOutsideClick = () => {},
  className,
}) {
  const wrapperRef = useRef();

  const handleClickOutside = useCallback(
    (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    },
    [onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
}

export default OutsideClickHandler;
