import Icon from 'components/utils/Icon';
import Portal from './Portal';
import { useRef } from 'react';

function Modal({
  children,
  onClose,
  width,
  openModal,
  title,
  scroll,
  canClose = true,
  showHeader = true,
  modalContentWrapperClassname = '',
  modalContentClassname = '',
}) {
  const modalRef = useRef(null);
  if (!openModal) return null;

  return (
    <Portal
      wrapperId="react-portal-modal-container"
      onClose={onClose}
      childrenRef={modalRef}
    >
      <div className={`modal wrap-modal`}>
        <div className="modal-dialog modal-md modal-dialog-scrollable">
          <div
            className={`modal-content ${modalContentClassname}`}
            ref={modalRef}
          >
            {showHeader && (
              <div
                className={`wrap-modal modal-header d-f ai-c ${
                  title ? 'jc-sb b-b' : 'jc-fe'
                }`}
              >
                {title && <h6>{title}</h6>}
                {canClose && <Icon type="x" onClick={onClose} />}
              </div>
            )}
            <div
              className={`modal-content-wrapper ${modalContentWrapperClassname}`}
            >
              {children}
            </div>
          </div>
        </div>
        <style jsx>
          {`
            :global(body) {
              overflow: ${openModal ? 'hidden!important' : ''};
            }
            .wrap-modal {
              display: flex;
              padding: 0.2em;
            }
            .b-b {
              border-bottom: var(--line) !important;
            }
            .wrap-modal .modal-md {
              max-width: ${width ? width : '40rem'};
              width: ${width && width};
            }
            .modal-dialog-scrollable {
              display: flex;
              max-height: calc(100% - 1rem);
            }
            .modal-dialog {
              position: relative;
              width: auto;
              margin: auto;
              pointer-events: none;
            }
            @media (min-width: 576px) {
              .wrap-modal .modal-dialog {
                margin-left: auto;
                margin-right: auto;
                margin-top: 0;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                min-height: calc(100% - 1rem);
                justify-content: center;
              }
              .wrap-modal .modal-dialog-scrollable {
                max-height: calc(100% - 8rem);
              }
              .modal-dialog-scrollable .modal-content {
                max-height: calc(100vh - 3.5rem);
              }
            }
            .wrap-modal .modal-content {
              border-radius: 0.25rem;
              border-width: 0;
              box-shadow: 0 4px 16px rgb(0 0 0 / 24%);
            }
            .modal-dialog-scrollable .modal-content {
              max-height: calc(100vh - 1rem);
              width: ${width && width};
              overflow: ${scroll && 'hidden scroll'};
            }
            .modal-dialog-scrollable .modal-content ::-webkit-scrollbar {
              width: 0.1em;
              position: absolute;
            }
            .modal-dialog-scrollable .modal-content ::-webkit-scrollbar-track {
              position: absolute;
            }
            .modal-dialog-scrollable .modal-content ::-webkit-scrollbar-thumb {
              background-color: var(--foreground);
              outline: 1px solid var(--foreground);
              position: absolute;
            }
            .modal-content {
              position: relative;
              display: flex;
              flex-direction: column;
              pointer-events: auto;
              background-color: var(--background-utils);
              background-clip: padding-box;
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 0.3rem;
              outline: 0;
            }
            .modal {
              position: fixed;
              top: 0;
              left: 0;
              z-index: 1050;
              width: 100%;
              height: 100%;
              overflow: hidden;
              outline: 0;
              background: rgba(0, 0, 0, 0.6);
            }
            .modal-close {
              display: none;
            }
            .modal-header :global(.icon) {
              cursor: pointer;
              z-index: 16;
              font-size: 16px;
              border: solid thin var(--grey-light);
              border-radius: 50px;
              transition: 0.3s;
            }
            .wrap-modal .modal-content > :first-child {
              border-top-left-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
            }
            .wrap-modal .modal-header {
              background-color: var(--background-utils);
              position: relative;
              border-style: none;
              padding: 1em;
            }
            .modal-content-wrapper {
              padding: 1em;
            }
            .popup-newsletter-wrapper {
              padding: 0;
            }
            .popup-newsletter-content {
              border-radius: 24px !important;
            }
          `}
        </style>
      </div>
    </Portal>
  );
}

export default Modal;
